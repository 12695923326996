import "./buyPlace.css";

const BuyPlace = () => {
  return (
    <div className="buy_place_wrapper text-md-start text-center">
      <div className="container">
        <div className="d-flex flex-md-row-reverse flex-column justify-content-between py-5">
          <div className="buy_place_image">
            <img className="img-fluid" src={require("./assets/image 6.png")} alt=""/>
          </div>
          <div className="buy_place_text">
            <h1 className="buy_place_main_title">KUPI SVOJE MJESTO!</h1>
            <p className="buy_place_content_text mt-4">
              Želite li da vaša firma bude dio napredne i moderne budućnosti
              grada Travnika? Sada imate priliku da to postignete! Stvorite svoj
              trag u historiji grada Travnika - sudjelujte u gradnji moderne
              makete Travnika 2035!
            </p>
            <a href="/kontakt">
            <button className="buy_place_button mt-4"><p className="buy_place_button_text">Prijavi se</p></button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyPlace;
