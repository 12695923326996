import React from "react";
import "../header/Header.css";

const Navbar = () => {
  return (
    <div className="header_navbar">
      <div className="navbar_inner">
        <div className="navbar_left">
          <a href="/">HOME</a>
        </div>
        <div className="navbar_right">
          <a href="/onama">O NAMA</a>
          <a href="/kontakt">KONTAKT</a>
          <a href="/sudjeluj">SUDJELUJ</a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;