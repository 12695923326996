import "./numbers.css";
import { ReactComponent as TowersIcon } from "./assets/building.svg";
import { ReactComponent as Bricks } from "./assets/arrow.svg";
import { ReactComponent as Children } from "./assets/people.svg";

const Numbers = () => {
  return (
    <>
      <div className="numbers_wrapper text-center">
        <div className="container">
          <div className="row py-5">
            <div className="col-12 col-md-4">
              <TowersIcon />
              <h1 className="numbers_card_title my-3">40 m2</h1>
              <h6 className="numbers_card_subtitle">Veličina makete</h6>
            </div>
            <div className="col-12 col-md-4 mt-5 mt-md-0">
              <Bricks />
              <h1 className="numbers_card_title my-3">300 000+</h1>
              <h6 className="numbers_card_subtitle">Broj LEGO kockica</h6>
            </div>
            <div className="col-12 col-md-4 mt-5 mt-md-0">
              <Children />
              <h1 className="numbers_card_title my-3">150+</h1>
              <h6 className="numbers_card_subtitle">Broj djece</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Numbers;
