import React from "react";
import "./Kontakt.css";
import Navbar from "../../components/navbar/Navbar";
import KontaktContent from "./components/KontaktContent";
import Footer from "../../components/footer/Footer";

function Kontakt() {
    return (
        <div className="kontakt">
            <Navbar />
            <KontaktContent />
            <Footer />
        </div>
    )
}

export default Kontakt