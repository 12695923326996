import "./footer.css";
import { ReactComponent as Globus } from "./assets/globe.svg";
import { ReactComponent as Instagram } from "./assets/instagram.svg";
import { ReactComponent as LinkedIn } from "./assets/linkedin.svg";
import { ReactComponent as Facebook } from "./assets/facebook.svg";

const Footer = () => {
  return (
    <div className="footer_wrapper padding_footer">
      <div className="container">
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
          <div className="footer_left_side text-light text-center text-md-start">
            <p className="footer_text fw-bold my-1">Mali programer</p>
            <p className="footer_text my-1">Mali Trg bb</p>
            <p className="footer_text my-1">72270 Travnik</p>
            <p className="footer_text my-1">061 475 790</p>
          </div>
          <div className="footer_right_side d-flex flex-column mt-5 mt-sm-0">
            <div className="main_image_footer">
              <img
                className="img-fluid"
                src={require("./assets/image 5.png")}
                alt=""
              />
            </div>
            <div className="side_images_footer w-100 d-flex justify-content-evenly mt-3">
              <a
                className="footer-links"
                href="https://maliprogramer.ba/"
                target="_blank"
                rel="noreferrer"
              >
                <Globus />
              </a>
              <a
                className="footer-links"
                href="https://www.instagram.com/mali.programer/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram />
              </a>
              <a
                className="footer-links"
                href="https://www.facebook.com/maliprogramertravnik/"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook />
              </a>
              <a
                className="footer-links"
                href="https://www.linkedin.com/company/mali-programer/?trk=public_post_main-feed-card-text&originalSubdomain=ba"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedIn />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
