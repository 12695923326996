import "../sudjeluj.css";

const Header = () => {
  return (
    <div className="container pt-5 mt-5">
      <div className="d-flex justify-content-between align-items-center flex-column text-center text-sm-start flex-sm-row">
        <div className="left_side">
            <h1 className="sudjeluj_title">KAKO SUDJELOVATI?</h1>
        </div>
        <div className="right_side">
          <img src={require("../assets/image 7.png")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Header;
