import Navbar from "../../components/navbar/Navbar"
import Header from "./components/Header"
import SudjelujText from "./components/SudjelujText"
import "./sudjeluj.css"
import Footer from "../../components/footer/Footer"

const Sudjeluj = () => {
    return(
        <div>
            <Navbar />
            <Header />
            <SudjelujText />
            <Footer />
        </div>
    )
}

export default Sudjeluj