import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Kontakt from './pages/kontakt/Kontakt';
import ONama from './pages/o-nama/ONama';
import Sudjeluj from './pages/sudjeluj/Sudjeluj';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/kontakt",
    element: <Kontakt />,
  },
  {
    path: "/onama",
    element: <ONama/>,
  },
  {
    path: "/sudjeluj",
    element: <Sudjeluj/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
