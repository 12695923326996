import Numbers from "./components/numbers/Numbers";
import Footer from "./components/footer/Footer";
import BuyPlace from "./components/buyPlace/BuyPlace";
import './App.css';
import Navbar from './components/navbar/Navbar';
import Header from './components/header/Header';
import Organizatori from './components/organizatori/Organizatori';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Numbers />
      <Organizatori />
      <BuyPlace />
      <Footer />
    </div>
  );
}

export default App;
