import React, { useState } from "react";
import "./KontaktContent.css";
import SendMessage from "./SendMessage";


function KontaktContent() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!name || !email || !message) {
      setLoading(false);
      setError("Sva polja su obavezna!");
      return;
    }
    setError("");
    SendMessage({ from_name: name, email, message, setLoading });
    setName("");
    setEmail(""); 
    setMessage("");
  };

  return (
    <div className="kontakt_content mt-5">
      <div className="kontakt_content_inner">
        <div className="kontakt_content_header">
          <h1>KONTAKT</h1>
        </div>
        <div className="kontakt_content_text">
          <div className="kontakt_inputs">
            <form
              onSubmit={handleSubmit}
            >
              <div className="kontakt_input_group">
                <label htmlFor="from_name">Ime i prezime</label>
                <input
                  type="text"
                  name="from_name"
                  id="name"
                  placeholder="Ime i prezime"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="kontakt_input_group">
                <label htmlFor="email">Email adresa</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email adresa"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="kontakt_input_group">
                <label htmlFor="message">Poruka za nas</label>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Poruka za nas"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <p className="error">{error}</p>
              <div className="kontakt_input_group button">
                <input 
                    type="submit"
                    disabled={loading}
                    className={loading ? "loading" : ""}
                    value={loading ? "" : "Pošalji"}
                />
              </div>
            </form>
          </div>
          <div className="kontakt_img">
            <img src={require("./assets/image 8.png")} alt="Kontakt" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default KontaktContent;
