import "../sudjeluj.css";

const SudjelujText = () => {
  return (
    <div className="container mb-5">
      <div className="sudjeluj_text_wrapper pt-5 text-center text-sm-start">
        <p className="sudjeluj_text_subtitle">Kupi svoje mjesto</p>
        <p className="sudjeluj_text">
          Želite li da vaša firma bude dio napredne i moderne budućnosti grada
          Travnika? Sada imate priliku da to postignete!Pomaganjem ovog projekta
          ostavićete svoj trag u historiji grada Travnika. Sudjelujte u gradnji
          moderne makete Travnika. Sudjelujte u gradnji moderne makete Travnika
          2035. i vaš logo će biti dio naše makete!
        </p>{" "}
        <p className="sudjeluj_text">
          Ova impresivna maketa grada od Lego kockica bit će izložena u centru
          grada i postat će turistička atrakcija koja će privući pažnju svih
          posjetitelja. Dođite i pridružite se našoj avanturi gradnje moderne
          makete Travnik 2035 i ostavite svoj pečat u historiji grada.
        </p>{" "}
        <p className="sudjeluj_text">
          Ova jedinstvena prilika donosi zajedništvo, kreativnost i radost, te će
          učiniti Travnik još atraktivnijim i privlačnijim za posjetitelje.Ne
          propustite ovu jedinstvenu priliku da vaša firma postane dio moderne i
          napredne budućnosti grada Travnika. Kontaktirajte nas i saznajte više
          o mogućnostima sudjelovanja. Ne čekajte, sudjelujte u gradnji Travnika
          2035 i podržite budućnost našeg grada!
        </p>
        <p className="sudjeluj_text_subtitle mt-5">Pokloni LEGO kockice</p>
        <p className="sudjeluj_text">
          Našli ste svoju davno zaboravljenu kolekciju LEGO kockica i ne znate
          što biste s njom, ili imate neke kockice koje vam nisu potrebne i samo
          vam smetaju po ladicama?
        </p>{" "}
        <p className="sudjeluj_text">
          Nema problema, imamo ideju! Ako se želite riješiti svojih starih,
          novih, neželjenih ili, naprosto, suvišnih kockica, rado ćemo ih preuzeti
          i dobro iskoristiti za pravljenje naše makete. Svoje kockice možete
          ostaviti u školi Mali programer svaki dan u periodu od 17:00 do 21:00
          ili da pošaljete na adresu:
        </p>{" "}
        <p className="sudjeluj_subtext mt-5">Mali programer</p>{" "}
        <p className="sudjeluj_subtext">Mali Trg bb</p>{" "}
        <p className="sudjeluj_subtext">72270 Travnik</p>{" "}
        <p className="sudjeluj_subtext">061 475 790</p>
      </div>
    </div>
  );
};

export default SudjelujText;
