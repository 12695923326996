import React from "react";
import "./Header.css";

function Header() {
  return (
    <div className="header mt-5">
      <div className="header_content">
        <div className="header_content_inner">
          <div className="header_content_left">
            <h1>LEGO TRAVNIK 2035</h1>
            <p>
              Budite dio ove nevjerovatne priče i zajedno gradimo Travnik koji
              nas čeka u budućnosti.
            </p>
            <a href="/sudjeluj">
              <button className="btn">Sudjeluj</button>
            </a>
          </div>
          <div className="header_content_right">
            <img src={require("./assets/people2.png")} alt="teaching" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
