const Header = () => {
  return (
    <div className="o_nama_header_wrapper mt-5">
      <div className="o_nama_header_shade">
        <div className="d-flex o_nama_header_display">
          <div className="col-4 d-flex justify-content-center align-items-center o_nama_header_organisator_wrapper">
            <img
              className="o_nama_header_organisators img-fluid"
              src={require("../assets/image 4.png")}
              alt=""
            />
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center o_nama_header_organisator_wrapper">
            <img
              className="o_nama_header_organisators img-fluid"
              src={require("../assets/image 5.png")}
              alt=""
            />
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center o_nama_header_organisator_wrapper">
            <img
              className="o_nama_header_organisators img-fluid"
              src={require("../assets/travnik.png")}
              alt=""
            />
          </div>
        </div>
      </div>
      <img
        className="o_nama_header_image"
        src={require("../assets/image 9.png")}
        alt=""
      />
    </div>
  );
};

export default Header;
