import "../oNama.css";

const ONamaText = () => {
  return (
    <div className="container mb-5">
      <h1 className="o_nama_text_title">O NAMA</h1>
      <p className="o_nama_text">
        Organizatori "Travnik 2035" su Općina Travnik, Katolički školski centar "Petar Barbarić" i
        Mali programer, događaj koji pruža priliku djeci da izraze svoju
        kreativnost u stvaranju moderne i napredne budućnosti grada Travnika.
        Ovaj događaj temelji se na stvaranju makete grada od Lego kockica sa
        sudjelovanjem djece i lokalne zajednice.
      </p>
      <p className="o_nama_text">
        Cilj je promovirati potencijal i napredak grada Travnika te potaknuti
        razvoj mlade talentirane djece. Preko 150 djece koristit će preko 300
        000 Lego kockica da bi izgradili maketu na 40m2. Bit će to nezaboravno
        iskustvo za djecu, a rezultat će biti impresivna vizija budućnosti
        grada. Sudjelovanje u gradnji makete potiče kreativnost djece i podržava
        lokalni razvoj, a također će privuči pažnju javnosti na potencijal i
        napredak grada Travnika.
      </p>
      <p className="o_nama_text">
        Škola programiranja i robotike Mali programer nastala je kao potreba
        prepoznata od strane osnivača za ovim vidom neformalnog obrazovanja i
        usmjeravanja djece. Činjenica je da je IT branša trenutno
        najatraktivnije globalno zanimanje, a ukoliko se to usmjeravanje kroz
        igru praktikuje od mladosti, rezultati su efektivniji.
      </p>{" "}
      <p className="o_nama_text">
        Pored svakodnevnih časova u prostorijama naše Škole, nerijetko izlazimo
        u susret formalnim obrazovnim ustanovama, gdje vršimo edukacije, a
        također smo u naš grad donijeli i globalni event - Kids Hack Day.
      </p>{" "}
      <p className="o_nama_text">
        Vizija Malog programera je kontinuiran rad sa mladima, što će u
        perspektivi i njima i njihovoj porodici itekako biti korisno.
      </p>
    </div>
  );
};

export default ONamaText;
