import React from "react";
import "./Organizatori.css";

function Organizatori() {
  return (
    <div className="organizatori">
      <div className="organizatori_inner organizatori_top">
        <div className="organizatori_lego_left">
          <img src={require("./assets/legos-in-png-8.png")} alt="LEGO" />
        </div>
        <div className="organizatori_lego_right">
          <h1>Pokloni LEGO kockice</h1>
          <p>
            Ako se želite riješiti svojih starih, novih, neželjenih ili, naprosto,
            suvišnih kockica, rado ćemo ih preuzeti i dobro iskoristiti za
            pravljenje naše makete.
          </p>
          <a href="onama">
            <button className="btn">Saznaj više</button>
          </a>
        </div>
      </div>
      <div className="organizatori_inner">
        <div className="organizatori_left">
          <h1>Organizatori</h1>
          <p>
            Organizatori "Travnik 2035" su Općina Travnik, Katolički školski
            centar "Petar Barbarić" i Mali programer. Događaj koji pruža djeci da
            izraze svoju kreativnost u stvaranju moderne i napredne budućnosti
            grada Travnika.
          </p>
        </div>
        <div className="organizatori_right">
          <div className="organizatori_right_img">
            <img src={require("./assets/travnik.png")} alt="Opčina Travnik" />
          </div>
          <div className="organizatori_right_img">
            <img src={require("./assets/ksc.png")} alt="KŠC Petar Barbarić" />
          </div>
          <div className="organizatori_right_img">
            <img
              src={require("./assets/maliProgramerLogo.png")}
              alt="Mali Programer"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organizatori;
