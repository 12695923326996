import Navbar from "../../components/navbar/Navbar";
import Header from "./components/Header";
import ONamaText from "./components/ONamaText";
import "./oNama.css";
import Footer from "../../components/footer/Footer";

const ONama = () => {
  return (
    <>
      <Navbar />
      <Header />
      <ONamaText />
      <Footer />
    </>
  );
};

export default ONama;
