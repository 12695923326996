import emailjs from "@emailjs/browser";

function SendMessage({from_name, email, message, setLoading}) {
    console.log(from_name, email, message);
    emailjs.init("gtJPe0nGiN1qMD9N8");
  emailjs.send(
    "service_559b3pm",
    "template_roe6rs5",
    {
        from_name,
        email,
        message
    },
  )
    .then((res) => {
        setLoading(false);
    })
    .catch((err) => {
        setLoading(false); 
    });
}

export default SendMessage;
